import {
  Box,
  Divider,
  Flex,
  List,
  ListItem,
  Menu,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import logo from "../../../assets/img/HapiMeetsLogoScroll.svg";
import Footer from "../../Footer";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    document.body.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Menu>
        <Flex
          style={{ padding: "10px" }}
          minH={"80px"}
          py={{ base: 2 }}
          px={{ base: 10 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "left", md: "space-between" }}
            alignItems="center"
            className="max-withs-frame"
            ml={{ base: "2%", md: "80px" }}
          >
            <>
              <Link to="/">
                <img src={logo} alt="logo" style={{ width: "150px" }} />
              </Link>
            </>
            <Flex alignItems="center">
              <Flex
                display={{ base: "none", md: "flex" }}
                ml={10}
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  spacing={7}
                  mr={{ base: "0px", md: "0px" }}
                  ml={{ base: "0px", md: "0px" }}
                  alignItems="center"
                ></Stack>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" borderWidth="1px" />
      </Menu>
      <Box className="terms-of-use">
        <Box
          mx="auto"
          maxWidth="1024px"
          width="100%"
          bgColor="#fff"
          className="p-frame"
          mt={10}
        >
          <Box>
            <Text className="h1" pt={10} pl={5}>
              Privacy Policy
            </Text>
          </Box>
          <Box className="max-withs-frame" padding="10px" marginTop="24px">
            <Box padding="10px">
              <Box>
                <Text className="sub1" pb={10}>
                  Your privacy is of the utmost importance at HapiMeets!
                </Text>
                <Text className="para1">
                  We have developed this policy to ensure that your data is
                  protected when you use the HapiMeets application.
                </Text>
                <Text className="para1" pt="24px">
                  A privacy policy is a document that sets out how we process
                  user data in our app. From the Privacy Policy, you can learn
                  what information we collect, how we use this information, with
                  whom we share it, how you can access or change it, and how we
                  protect information.
                </Text>
                <Text className="para1" pt="24px">
                  We value your time and attention and try to convey information
                  in an easy to understand way.
                </Text>
                <Text className="para1" pt="24px">
                  This Privacy Policy applies from August 1, 2023.{" "}
                </Text>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  Important point
                </Text>
              </Box>
              <Box className="para1">
                This privacy policy applies to the HapiMeets Guest and HapiMeets
                Host applications as interconnected apps for consumption of such
                services as: personal or business communication, providing and
                receiving consultations, tutoring, as well as any services that
                can be provided through video conferences and text messages.
              </Box>

              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  1. Who we are
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                HapiMeets applications are managed by HAPIMEETS PTE. LTD.
              </Box>
              <Box className="para1">
                180 Cecil Street #12-01 Bangkok Bank Building Singapore 069546
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  2. Where this privacy policy applies
                </Text>
              </Box>
              <Box className="para1">
                This Privacy Policy applies to HapiMeets applications (app,
                apps). This Policy is a legally binding agreement between you
                ("User", "you" or "your") and HapiMeets PTE.LTD ("HapiMeets",
                "we", "us" or "our").
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  3. Information we collect
                </Text>
              </Box>
              <Box className="para1" mb="24px">
                We collect information that you provide when you register a
                profile and use the HapiMeets applications, as well as
                application services, so that you can find the right person for
                communication, consultation or other similar services
                implemented in HapiMeets.
              </Box>
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
                lineHeight="30px"
              >
                <Text className="sub1" pt={10} pb={5} letterSpacing="-0.5px">
                  Information you give us includes:
                </Text>
                <Box className="para1" pb={5}>
                  When you create an account, you provide us with your email
                  address or phone number.
                </Box>
                <Box className="para1" pb={5}>
                  You can also share information about your gender, date of
                  birth, your interests and preferences for the partner you are
                  looking for.
                </Box>
                <Box className="para1" pb={5}>
                  When you complete your profile, you can add information about
                  your location and write details about you in your bio. You can
                  also add a profile photo.
                </Box>
                <Box className="para1" pb={5}>
                  To add images you need to allow us access to your camera or
                  photo album.
                </Box>
                <Box className="para1" pb={5}>
                  You are in control of your profile and you can correct or
                  update information about yourself (except for gender) in your
                  profile settings.
                </Box>
                <Box className="para1" pb={5}>
                  After you create a profile, we automatically track some
                  information, such as your IP address.
                </Box>
                <Box className="para1" pb={5}>
                  When you pay for a service provided through our application
                  you provide your debit or credit card number or other
                  financial information to the payment system
                </Box>
                <Box className="para1" pb={5}>
                  When you contact support, we collect the information you
                  provide in the process of interaction.
                </Box>
                <Box className="para1" pb={10}>
                  If necessary, we also process your chats with other users.
                </Box>
              </Box>
              <br />
              <br />
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
              >
                <Text className="sub1" pt={10} pb={5}>
                  Information from other sources:
                </Text>
                <Box className="para1" pb={5}>
                  In the event that you create or log into your account through
                  Google, Apple or Facebook - we receive information from these
                  social networks (such as email address or mobile phone
                  number).
                </Box>
                <Box className="para1" pb={5}>
                  Other users may also provide information about you, for
                  example, when contacting support or when using our services.
                </Box>
                <Box className="para1" pb={5}>
                  We also store information about your profile settings, for
                  example, the interface language, your notification settings,
                  your photo visibility settings in order to make it easier for
                  you to use the app.
                </Box>
                <Box className="para1" pb={10}>
                  We store your contact list, chat history, favorites and
                  ignores, ratings, likes and dislikes, messages you sent and
                  received, and other information about your activities in the
                  app to help you communicate better using our app.
                </Box>
              </Box>
              <Box className="para1" pt={10} pb={5}>
                We also collect the information with your consent such as:
              </Box>
              <Box className="para1" pb={5}>
                Geolocation data such as latitude and longitude.
              </Box>
              <Box className="para1" pb={5}>
                Any other materials you willingly submit to us such as articles,
                images, feedback, etc.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  4. How we use information
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                We use your information to provide services to you, and also to
                continuously improve these services and make using them as
                convenient as possible for you. We use your personal
                information:
              </Box>

              <List styleType="disc" pl={8} pb={10}>
                <ListItem className="para1">
                  To manage your account and provide our services;
                </ListItem>
                <ListItem className="para1">
                  To provide you with customer support and respond to your
                  inquiries.
                </ListItem>
                <ListItem className="para1">
                  To help you connect with other users;
                </ListItem>
                <ListItem className="para1">
                  To show members’ profiles to one another.
                </ListItem>
                <ListItem className="para1">
                  To analyze and improve our services for you.
                </ListItem>
                <ListItem className="para1">
                  not use HapiMeets or the information posted on HapiMeets for
                  purposes other than those specified in the Terms;
                </ListItem>
              </List>
              <Box className="para1" pb={10}>
                We also use information about you to ensure your safety. And in
                order to prevent, detect and combat fraud and other illegal or
                unauthorized activities, and to ensure legal compliance.
              </Box>
              <Box
                bg="#E8EAED"
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
                pt={5}
              >
                <Text className="sub1" pt={5} pb={5}>
                  Agreement:
                </Text>
                <Box className="para1" pb={3}>
                  If you provide us with information that may be considered
                  proprietary or confidential in certain jurisdictions, you
                  consent to the processing of that information in accordance
                  with this Privacy Policy.
                </Box>
                <Box className="para1" pb={10}>
                  You can withdraw your consent, for example by changing some of
                  your profile settings, or by deleting data from your content.
                  Also, you can always withdraw your consent by sending a
                  request to the contacts listed at the end of the Policy.
                </Box>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={10}>
                  5. How we share information
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                In the HapiMeets apps, you can easily make new acquaintances,
                communicate with other users, talk about yourself etc. When
                using the apps, you should be aware that any information
                published will be publicly available to our users and our
                service departments. You can also use our chat, which allows you
                to chat with other users.
              </Box>
              <Box className="para1" pb={5}>
                We recommend and encourage you (and all of our users) to think
                about the information you disclose. We do not recommend that you
                provide email addresses, URLs, messenger data, phone numbers,
                full names and addresses, credit card information, national
                identification numbers, driver's license information, or other
                sensitive information in your profile.
              </Box>
              <Box className="para1" pb={5}>
                Remember that it is forbidden to use the personal data of other
                users for commercial purposes, for spam, it is also forbidden to
                harass other users or issue threats. HapiMeets reserves the
                right to block/delete (suspend the provision of services) a
                user's account at any time for illegal use of personal data of
                other users or for any other violation of our terms.
              </Box>
              <Box className="para1" pb={5}>
                We may partner with third parties in order to serve our
                application (and the data it contains) and our customers, as
                well as for advertising and marketing, payment processing and
                security purposes. In the case of transferring information to
                our partners, it is transferred in an unreadable, hashed form.
              </Box>
              <Box className="para1" pb={5}>
                We may share information with law enforcement authorities when
                required to do so by law or to protect legal rights.
              </Box>
              <Box className="para1" pb={5}>
                We may use and share non-personal information (meaning
                information that, by itself, does not identify who you are) such
                as device information, general demographics, general behavioral
                data, geolocation in non-identified form).{" "}
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  6. Terms of data storage and deletion
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                HapiMeets is guided by the provisions set forth in the ISO / IEC
                27001 standard. When working with personal data received from
                our clients (such as name and phone number), we process and
                store them using software certified by ISO 27001.
              </Box>
              <Box className="para1" pb={5}>
                We keep your personal information for as long as it is necessary
                for the purposes specified in section 4 of this Policy, as well
                as in accordance with the law.
              </Box>
              <Box className="para1" pb={5}>
                If you decide to stop using the HapiMeets services, we will
                automatically close your account if you are inactive for two
                years. After your account is closed, we will delete your
                personal information as follows:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  for 3 months after closing an account and for 12 months in
                  case of account blocking, we store information for security
                  purposes, as well as the safety of our members in case it may
                  be necessary to investigate illegal or malicious activities.
                </ListItem>
              </List>

              <Box className="para1" pb={5}>
                After this period, your data is deleted and limited information
                is stored, such as:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  transaction data for 10 years to comply with tax and
                  accounting legal requirements
                </ListItem>
                <ListItem className="para1">
                  credit card information for the period during which the user
                  can dispute transactions{" "}
                </ListItem>
                <ListItem className="para1">
                  "traffic data" for one year to comply with legal data
                  retention obligations.{" "}
                </ListItem>
              </List>
              <Box className="para1" pb={5}>
                We also keep records of consents given to us by members for five
                years to ensure compliance with applicable laws.
              </Box>
              <Box className="para1" pb={5}>
                We retain limited information based on our legitimate interests
                for a period of 5 years in order to make effective decisions to
                serve our customers and to protect ourselves in the event of a
                claim:
              </Box>
              <List styleType="disc" pl={8} pb={5}>
                <ListItem className="para1">
                  customer service records and supporting data
                </ListItem>
                <ListItem className="para1">
                  inaccurate download/purchase location.
                </ListItem>
              </List>
              <Box className="para1" pb={5}>
                Profile data is stored for 1 year in the event of legal
                proceedings, in order to establish, exercise or defend legal
                claims.{" "}
              </Box>
              <Box className="para1" pb={5}>
                We also store the data of members who have been banned from
                using the applications in order to prevent the creation of a new
                account.{" "}
              </Box>
              <Box
                bg="#E8EAED"
                pt={5}
                pl={{ base: 5, md: 12 }}
                pr={{ base: 5, md: 12 }}
              >
                <Text className="sub1" pt={5} pb={5}>
                  Important:
                </Text>
                <Box className="para1" pb={10}>
                  We retain information based on our legitimate interest when
                  there is an unresolved or potential issue, claim or dispute
                  that requires us to retain information (for example, if we
                  receive a valid subpoena or request to retain data).
                </Box>
              </Box>
              <Box>
                <Text className="sub1" pt={10} pb={5}>
                  7. Your rights
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                You can change your profile information at any time. We strongly
                recommend that you change your password periodically to help
                reduce the risk of unauthorized access to your account. If you
                have any question about those tools and settings, please contact
                our support team for help.
              </Box>
              <Box className="para1" pb={5}>
                Uninstall. You can stop all information collection by an app
                through uninstalling it using the standard uninstall process for
                your device. Remember that uninstalling an app does NOT close
                your account. To close your account, please use the
                corresponding functionality.
              </Box>
              <Box className="para1" pb={5}>
                You can also change the visibility of your profile using the
                visibility settings in the profile settings menu.
              </Box>
              <Box className="para1" pb={5}>
                If you have any questions about the information that we have or
                a request to change it or the need to delete it, you can always
                contact us at the contacts listed at the end of this page.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  8. Changes to the privacy policy
                </Text>
              </Box>
              <Box className="para1">
                Due to the fact that we work daily to improve our service and
                all related provisions and documents, we may make changes to
                this privacy policy. We will notify you if the document is
                updated.
              </Box>
              <Box className="para1"></Box>

              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  9. Contact us
                </Text>
              </Box>
              <Box className="para1">
                You can always contact us by sending an email to &nbsp;
                <span style={{ color: "#0055C1" }}>support@hapimeets.com</span>
              </Box>
              <Box className="para1" pb={10}>
                Also, our users have access to chatting applications. Just go to
                "messages" to text Support.
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default PrivacyPolicy;
