import {
  Box,
  Divider,
  Flex,
  List,
  ListItem,
  Menu,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import logo from "../../../assets/img/HapiMeetsLogoScroll.svg";
import Footer from "../../Footer";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    document.body.style.scrollBehavior = "auto";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Menu>
        <Flex
          style={{ padding: "10px" }}
          minH={"80px"}
          width={{ base: "390px", md: "auto" }}
          py={{ base: 2 }}
          px={{ base: 10 }}
          align={"center"}
        >
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "space-between" }}
            alignItems="center"
            className="max-withs-frame terms-logo-wrapper"
            ml="80px"
          >
            <>
              <Link to="/">
                <img className="terms-logo" src={logo} alt="logo" />
              </Link>
            </>
            <Flex alignItems="center">
              <Flex
                display={{ base: "none", md: "flex" }}
                ml={10}
                alignItems="center"
              >
                <Stack
                  direction={"row"}
                  spacing={7}
                  mr={{ base: "0px", md: "0px" }}
                  ml={{ base: "0px", md: "0px" }}
                  alignItems="center"
                ></Stack>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Divider orientation="horizontal" borderWidth="1px" />
      </Menu>
      <Box className="terms-of-use">
        <Box
          mx="auto"
          maxWidth="1024px"
          width="100%"
          bgColor="#fff"
          className="p-frame"
          mt={10}
        >
          <Box>
            <Text className="h1" pl={2} pt={10}>
              Terms of Use
            </Text>
          </Box>
          <Box className="max-withs-frame" padding="10px" marginTop="24px">
            <Box padding="10px">
              <Box>
                <Text className="para1" pb={10}>
                  Welcome to HapiMeets - a service that brings together people
                  who are looking for communication on various topics and those
                  who are happy to help!
                </Text>
                <Text className="para1" pb={10}>
                  The HapiMeets Terms of Use (Terms) govern the relationship
                  between the users (you) of the HapiMeets Guest and HapiMeets
                  Host applications (apps) and HAPIMEETS PTE. LTD. (Company).
                </Text>
                <Text className="para1" pb={5}>
                  Use of the HapiMeets Apps signifies your acceptance of these
                  Terms and Conditions.
                </Text>
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  1.What is HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                HapiMeets is a service for those who wish to receive a service
                via video chat, and for those posting information about
                themselves as a provider of such a service.
              </Box>
              <Box className="para1" pb={10}>
                HapiMeets are two interconnected applications for service
                consumers (HapiMeets Guest) and service providers (HapiMeets
                Host) described above.
              </Box>
              <Box className="para1" pb={10}>
                Development of the HapiMeets applications is managed by the
                company HAPIMEETS PTE. LTD. which has ownership of the apps.{" "}
              </Box>
              <Box className="para1" pb={10}>
                The Company provides registered users with the technical ability
                to post information about themselves in the HapiMeets Guest and
                HapiMeets Host applications by creating a personal profile. The
                Company provides HapiMeets Guest users with the technical
                ability to search and view the profiles of service providers who
                have placed their data in the HapiMeets Host application for the
                purposes stipulated in the Terms.
              </Box>
              <Box className="para1" pb={5}>
                HAPIMEETS PTE. LTD. is not a participant, video chat organizer,
                buyer, seller, employer, intermediary, agent, representative of
                any user, beneficiary or other interested person in relation to
                transactions between users. Users use the information posted in
                HapiMeets applications to enter into transactions for the
                provision and receipt of services through video chat
                communication at their own risk without direct or indirect
                participation or control from the Company.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  2. Registration in HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                You can register in the apps with your email address, mobile
                phone number or through your social media profile presented in
                the HapiMeets Guest and HapiMeets Host login form. After
                registration, we will create your unique profile.
              </Box>
              <Box className="para1">
                By registering with the HapiMeets Apps and each time you log in
                to HapiMeets, you acknowledge that you are aware of and agree to
                our Terms of Use and{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#0055C1" }}
                >
                  Privacy Policy.
                </a>
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  3. Login to HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                You can log in to your HapiMeets profile (login) either with the
                mobile phone number/email address provided in your profile, or
                with the Facebook, Google or Apple account associated with your
                profile. In the first case, you need to enter a password, in the
                second you must first log in to the corresponding social
                network.
              </Box>
              <Box className="para1">
                It is your responsibility to maintain the confidentiality of
                your HapiMeets login information. If you have reason to believe
                that someone has gained unauthorized access to your data, please
                notify us immediately. All actions taken in the HapiMeets Apps
                by a person authorized with your login details will be
                considered as taken by you. You will be responsible for such
                actions.
              </Box>
              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  4. User information
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                The information you provide when registering an account (such as
                your email address or mobile phone number) must be correct in
                order for the account registration to be successful.
              </Box>
              <Box className="para1" pb={10}>
                The information that you place about yourself in your profile
                must be up-to-date and must not violate the law and the rights
                of third parties. Update your HapiMeets profile as needed.
              </Box>
              <Box className="para1" pb={10}>
                In some cases, if there are signs of a violation of the rules or
                dishonest behavior, we may ask you to explain, as well as change
                your behavior to comply with the rules. A video image of a face
                may be requested to confirm that the profile belongs to a real
                person and is not automatically registered.
              </Box>
              <Box className="para1">
                Such actions are necessary to ensure a welcoming and positive
                atmosphere of our service for all users of our apps.
              </Box>

              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  5. User content
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                User Content is the text and images that HapiMeets users post
                about themselves on their profile and when writing a review.
              </Box>
              <Box className="para1" pb={10}>
                By creating your profile, you express information that is
                available for viewing by other users of HapiMeets. By leaving
                feedback on service providers, you express your subjective
                opinion and evaluate your communication with another user.
              </Box>
              <Box className="para1" pb={10}>
                The Company does not act as a distributor of user-generated
                content. You are the owner of all information that you post on
                HapiMeets. You independently create, modify, place in the public
                domain for an unlimited number of persons and delete content
                (including personal data) without our participation or consent.
              </Box>
              <Box className="para1" pb={10}>
                We strongly ask you not to place personal data of third parties
                in applications. We do not ask for any information about third
                parties, except in cases of violation of the atmosphere of
                goodwill or the rules established by HapiMeets. If there is a
                violation of your rights and you yourself report this to the
                customer support service, we may ask you to describe the
                situation and the nickname of the HapiMeets user you are
                complaining about.
              </Box>
              <Box className="para1" pb={10}>
                The purpose for which users post information on HapiMeets is to
                establish contact between a potential consumer who is interested
                in communication via video chat and a potential service
                provider. This refers to services such as conducting an online
                lesson, consulting or just communicating with someone who needs
                it.
              </Box>
              <Box className="para1" pb={10}>
                Posting user content is allowed for informational purposes only.{" "}
              </Box>
              <Box className="para1">
                We urge service providers not to post false information about
                themselves in order to receive additional requests for video
                meeting communication. We encourage users not to leave false
                information in reviews. It is also unacceptable to “cheat”
                reviews, including leaving reviews for a fee that help improve
                the rating of the supplier. Collecting, copying, using (and any
                other actions in relation to) user content for purposes not
                provided for in the Terms are prohibited.{" "}
              </Box>

              <Box>
                <Text className="sub1" pt={5} pb={5}>
                  6. User communication in HapiMeets
                </Text>
              </Box>
              <Box className="para1" pb={10}>
                Users of HapiMeets Guest and HapiMeets Host applications can
                exchange messages with each other. The settings of the
                applications are such that they facilitate two-way communication
                (when the user of the Guest service interacts with the user of
                the Host). The initiator in this case is the user of the
                HapiMeets Guest application.
              </Box>
              <Box className="para1" pb={10}>
                In the same order, users can use the appointment booking service
                (and send the corresponding request), as well as make a call
                with the possibility of video communication.
              </Box>
              <Box className="para1" pb={10}>
                It is forbidden to use messages and calls for any other purpose.
                For your safety, do not transfer communication between users to
                other instant messengers or other communication channels.
              </Box>
              <Box className="para1" pb={10}>
                Your personal data, such as your email address or mobile phone
                number, is not reflected in your profile and is not visible to
                other users, unless you have placed this information yourself,
                for example, in your bio. We strongly recommend that you do not
                post personal information about yourself. You are responsible
                for posting such information.
              </Box>
              <Box className="para1">
                We have the right to record, decrypt, analyze and store messages
                sent in the HapiMeets service, as well as calls and video chats
                made through the HapiMeets application and transcripts of such
                calls. Messages in HapiMeets, calls and video calls in
                applications are not personal. We may selectively listen to call
                recordings, video chats, conversations, and call transcripts to
                enforce HapiMeets Terms of Service, stop illegal behavior and/or
                improve HapiMeets.
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  7. HapiMeets communication with users
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                We can send you system notifications that cannot be unsubscribed
                from:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    for example to confirm registration or inform about
                    restriction of access to the profile.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                Just as the above system notifications, the following cannot be
                unsubscribed from:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    service notifications related to your activities on
                    HapiMeets: for example, receiving or accepting a request,
                    receiving a message from another user. Similarly, video chat
                    reminders 10, 3 or 1 minute before a scheduled video call.
                    marketing and information notifications.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                We may send you messages to the email address or phone number
                provided when registering your account or in your profile, to
                your device in the form of push notifications as well as in
                messages within HapiMeets applications.
              </Box>
              <Box className="para1">
                You can manage service alerts, informational alerts and
                marketing alerts in your profile settings. Settings may vary
                depending on the device.{" "}
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  8. Deleting a profile
                </Text>
              </Box>
              <Box className="para1">
                You have the right to decide at any time to delete your
                HapiMeets profile. In this case you need to close your profile
                in the application settings. Next, we will delete your profile
                if you do not use it once within two years.
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  9. Obligations of users
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                By using HapiMeets and interacting with the Company, you agree
                to:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    strictly comply with all legal requirements;
                  </ListItem>
                  <ListItem>comply with the HapiMeets Terms of Use,</ListItem>
                  <ListItem>not send spam to users;</ListItem>
                  <ListItem>
                    not automatically copy information to/from HapiMeets,
                    including User Content;
                  </ListItem>
                  <ListItem>
                    not use obscene language, images and statements that provoke
                    cruelty, hatred or disrespect, contain threats or insults,
                    justify illegal actions, or do not comply with moral
                    standards or business practices;
                  </ListItem>
                  <ListItem>
                    not use HapiMeets or the information posted on HapiMeets for
                    purposes other than those specified in the Terms;
                  </ListItem>
                  <ListItem>
                    not upload or use malware in HapiMeets applications;
                  </ListItem>
                  <ListItem>
                    not take actions that may interfere with the para1 operation
                    of applications.
                  </ListItem>
                </List>
              </Box>

              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  10. Violations and consequences
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                We are committed to making HapiMeets a user-friendly and secure
                service, so we reserve the right to randomly check that users
                comply with the HapiMeets Terms of Use at any time and at our
                sole discretion. To do this, we can use automated methods.
              </Box>
              <Box className="para1" pb={5}>
                We have the right to take action in case of detection of
                violations, or actions to prepare for a violation of the law,
                the rights of third parties or the HapiMeets Terms of Use:
              </Box>
              <Box className="para1" pb={5}>
                <List styleType="disc" pl={8}>
                  <ListItem>
                    restrict access to your profile or some functionality of the
                    application, for example, restrict access to the HapiMeets
                    messenger;
                  </ListItem>
                  <ListItem>
                    restrict your content from appearing in search results;
                  </ListItem>
                  <ListItem>reject or block your content;</ListItem>
                  <ListItem>
                    restrict access to HapiMeets from certain IP addresses and
                    phone numbers.
                  </ListItem>
                </List>
              </Box>
              <Box className="para1" pb={5}>
                We may restrict access to a profile in the event of complaints
                from other users regarding such profile, spamming, repeated or
                malicious violations of the HapiMeets Terms of Use, profile
                hacking, user failure to provide information at our request,
                inaccurate reviews, or if we notice in user behavior signs of
                fraud in HapiMeets, the imposition of additional services and
                conditions, or if the user has registered a new profile in
                HapiMeets instead of a previously blocked one.
              </Box>
              <Box className="para1" pb={5}>
                In exceptional cases, when the user completely eliminates the
                violations committed, we can restore access to the profile in
                order to maintain the loyalty of the HapiMeets user.
              </Box>
              <Box className="para1" pb={5}>
                We are not responsible for potential losses caused to users in
                connection with the adoption of measures to prevent and stop
                violations in HapiMeets. We recommend that you keep a backup
                copy of your content on your device.
              </Box>
              <Box className="para1" pb={5}>
                In case of violation of the law or the rights of third parties
                when using HapiMeets, you bear full personal and legal
                responsibility for your actions.
              </Box>
              <Box>
                <Text className=" sub1" fontWeight={700} pt={5} pb={5}>
                  11. Final provisions
                </Text>
              </Box>
              <Box className="para1" pb={5}>
                Any form of interaction with HapiMeets (including viewing of
                information) confirms your acceptance of the Terms. The
                Company's obligation to provide access to the functionality of
                the HapiMeets Applications is based on your obligation to comply
                with the Terms.
              </Box>
              <Box className="para1" pb={5}>
                The functionality of HapiMeets may be changed, supplemented or
                discontinued at any time without prior notice to users. Use of
                the HapiMeets Applications is offered on an "as is" basis, i.e.
                in the form and to the extent that the Company provides the
                functionality of HapiMeets at the time of accessing them. We are
                not responsible for temporary failures and interruptions in the
                operation of applications and the loss of information caused by
                them. We are not responsible for any indirect, incidental,
                unintentional damages, including lost profits or lost data,
                damage to honor, dignity or business reputation caused in
                connection with the use of HapiMeets.
              </Box>
              <Box className="para1" pb={5}>
                Singapore law shall apply to all disputes between the Company
                and users. All disputes shall be submitted to the court in
                accordance with the territorial jurisdiction at the location of
                the Company, unless otherwise provided by law. We update the
                HapiMeets Terms of Use as necessary. By continuing to use
                HapiMeets after the Terms have changed, you signify your
                acceptance of the changes made to them.
              </Box>
              <Box className="para1" pb={10}>
                If you have any questions about the HapiMeets Terms of Use, you
                can contact the Support team using the messenger in the
                application or by emailing us at{" "}
                <span style={{ color: "#0055C1" }}>support@hapimeets.com</span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

export default TermsOfUse;
